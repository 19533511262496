import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Cart from './components/Cart';
import Home from './components/Home';
import LoginModal from './components/LoginModal';
import Profile from './components/Profile';
import Checkout from './components/Checkout';
import WorksList from './components/WorksSubCatList';
import VendorForm from './components/Vendorform';
import QuoteWorks from './components/QuoteWorks';
import ImageUpload from './components/ImageUpload';
import { UserProvider } from './components/UserContext';
import { CartProvider } from './components/CartContext';




import './App.css';
import { CategoryProvider } from './components/CategoryContext';
import WorkOptionsModal from './components/WorkOptionsModal';


function App() {

  
  return (
    <CategoryProvider>
    <UserProvider>
         <CartProvider>
          
    <Router>
      <div className="app-container">
        <Header />
        <main className="content-container">
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/home" element={<Home />} /> 
          <Route path="/cart" element={<Cart />}/> 
          <Route path="/login" element={<LoginModal/>} /> {/* Empty div to handle the route */}
          <Route path="/home_services" element={<WorksList />} />
          <Route path="/services/:sub_category" element={<QuoteWorks />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/checkout" element={<Checkout   />} />
          <Route path="/partner" element={<VendorForm   />} />
          <Route path="/workoptions" element={<WorkOptionsModal   />} />
          
        </Routes>
        </main>
        <h1>Upload an Image</h1>
        <ImageUpload />
        
        <Footer />
      </div>
    </Router>
    </CartProvider>
    </UserProvider>
    </CategoryProvider>
  );
}

export default App;