import React, { useState } from "react";
import axios from "axios";


// For uploading image into cloudinary

const ImageUpload = () => {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      alert("Please select an image first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "YOUR_UPLOAD_PRESET"); // Set in Cloudinary settings

    try {
      const response = await axios.post(
        // CLOUD NAME:dzc8sqjnv
        "https://api.cloudinary.com/v1_1/dzc8sqjnv/image/upload",
        formData
      );
      setImageUrl(response.data.secure_url); // Get the uploaded image URL
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      <button onClick={handleUpload}>Upload</button>
      {imageUrl && (
        <div>
          <h3>Uploaded Image:</h3>
          <img src={imageUrl} alt="Uploaded" width="200px" />
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
