// UserContext.js
import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
        console.log(user)
    }, []);

    useEffect(() => {
        if (user) {
            
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');

        }

        console.log(user)
    }, [user]);

    const login = async (phno, password) => {
        try {
            const response = await fetch('https://api.weclear.in/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phno, password }),
                credentials: 'include',
            });

            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
                console.log("Login successful",userData);
                return true;
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    const signup = async (firstName, lastName, email, phno, password) => {
        try {
            const response = await fetch('https://api.weclear.in/auth/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ firstName, lastName, email, phno, password }),
                credentials: 'include',
            });
            if(response.status === 409){
                console.log("User exists");
                return 0;
            }
            if (response.ok) {
                const userData = await response.json();
                setUser(userData);
                return 1;
            } else {
                throw new Error('Registration failed');
            }
        } catch (error) {
            console.error(error);
            return -1;
        }
    };

    const logout = () => {
        setUser(null);

        fetch('https://api.weclear.in/auth/logout', {
            method: 'POST',
            credentials: 'include',
        });
    };

    const updateUser = async (userId, userData) =>{
        console.log(JSON.stringify({ userData }));
        try{
        const response = await fetch(`https://api.weclear.in/auth/updateUser/${userId}`,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify( userData ),
            credentials: 'include',
        });

        if (response.ok) {
            const updatedUser = await response.json();
            setUser(updatedUser); // Update user context with the new data
        } else {
            throw new Error('Update failed');
        }
        } catch(error) {
            console.error(error);
        }

    }

    return (
        <UserContext.Provider value={{ user, login, signup, logout, updateUser }}>
            {children}
        </UserContext.Provider>
    );
};