import { FaTimesCircle,  FaCheckCircle} from 'react-icons/fa';
import './styles/PasswordStrength.css'
const PasswordStrength = ({ password }) => {
    const rules = [
      { regex: /.{8,}/, label: "At least 8 characters" },
      { regex: /[a-zA-Z]/, label: "At least 1 letter" },
      { regex: /\d/, label: "At least 1 number" },
      { regex: /[@$!%*?&]/, label: "At least 1 special character (@$!%*?&)" },
    ];
  
    const allValid = rules.every((rule) => rule.regex.test(password));
  
    return (
      <div>
        <ul className="password-rules">
          {rules.map((rule, index) => (
            <li key={index} className={rule.regex.test(password) ? "valid" : "invalid"}>
              {rule.regex.test(password) ? (
                <FaCheckCircle className="valid-icon" />
              ) : (
                <FaTimesCircle className="invalid-icon" />
              )}
              {rule.label}
            </li>
          ))}
        </ul>
        {!allValid && (
          <p className="error text-danger">
            Password must meet all the above criteria.
          </p>
        )}
      </div>
    );
  };

  export default PasswordStrength;