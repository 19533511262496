import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Carousel, Card, Col, Row, Container} from 'react-bootstrap';
import './styles/Home.css';


function Home() {
    const navigate = useNavigate();

    const services = [
        { title: "Purely Aesthetic", description: "",imgUrl:"/aesthetic.png",sub_category:"PURELY_AESTHETIC"},
        { title: "Core Infrastructure", description: "",imgUrl:"/core.png",sub_category:"CORE_INFRASTRUCTURE"},
        { title: "Security Surveillance", description: "",imgUrl:"/security.png",sub_category:"SECURITY_SURVEILLANCE"},
        { title: "Home Services ", description: "",imgUrl:"/cleaning.png",sub_category:"HOME_SERVICES"},
   ];

      const steps = [
        { title: "Search Service", description: "Find the service you need from our wide range of offerings.", imgUrl: "https://i.ytimg.com/vi/_NltVXqwGQw/maxresdefault.jpg" },
        { title: "Book Your Slot", description: "Choose your preferred time and book a slot instantly.", imgUrl: "https://img.freepik.com/premium-vector/hand-holding-mobile-phone-with-check-mark_578506-259.jpg" },
        { title: "Relax", description: "Our professionals will visit you at your chosen time.", imgUrl: "https://cdn-icons-png.freepik.com/512/3590/3590601.png" }
      ];
    

    return (
        <Container>
        
        {/* Carousel */}
        <div className='carousel-container'>
            <Carousel interval={3000}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://mobisoftinfotech.com/resources/wp-content/uploads/2018/08/Banner.png"
                  alt="First slide"
                />
                
              </Carousel.Item>
        
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://allservices.infineto.in/wp-content/uploads/2022/11/Home-Services-Image-30-march-2020.png"
                  alt="Second slide"
                />
                
              </Carousel.Item>
        
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://ucarecdn.com/69325cfe-4d60-424d-9770-b0ae9ae1b047/-/format/auto/-/preview/3000x3000/-/quality/lighter/25%25-Welcome-Coupon.png"
                  alt="Third slide"
                />
                
              </Carousel.Item>
            </Carousel>
        </div>


        {/* Recommended Services */}

        <div className="expertise mt-5">


          <h3 className="my-4">Our Expertise</h3>
    
          
          <Row className="justify-content-center">
      {services.map((service, index) => {
        // Determine the path based on the sub_category
        const path = service.sub_category === "HOME_SERVICES" 
          ? "/home_services" 
          : `/services/${service.sub_category}`;

        return (
          <Col xs={12} md={6} lg={3} key={index} className="mb-4">
            <Card className="border-0 card-lg-equal-height" onClick={() => navigate(path)}>
              <Card.Img className="card-img-equal-height serviceImage" variant="top" src={service.imgUrl} />
              <Card.Body>
                <Card.Title className="title">{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
      </div>


      {/* Our mission */}
      <div className='mission text-center'>
        <img src='/m1.jpg'></img>
        <h3>Our Mission</h3>
        <p>At we clear, our mission is to provide top-quality commercial cleaning services to our clients at affordable prices. We strive to exceed expectations and deliver outstanding results every time.</p>
      </div>



      {/* How it works section */}
      <div className="how-it-works mt-5">
      <h3 className=" my-4">What you need to do</h3>
      <Row className="text-center justify-content-center">
        {steps.map((step, index) => (
          <Col xs={12} md={4} key={index} className="mb-4">
            <Card className="h-100 border-0 jd-flex text-center align-items-center justify-content-center">
              <Card.Body className='d-flex flex-column align-items-center'>
                <span><img className='step-img' src={step.imgUrl}></img></span>
                <Card.Title>{step.title}</Card.Title>
                <Card.Text>{step.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

      </Container>
    );
}

export default Home;