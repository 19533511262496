import React, { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { FaRegUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import { UserContext } from "./UserContext";
import { CartContext } from "./CartContext";

import "./styles/Header.css";
import Search from "./Search";
import CategoryModal from "./CatgoryModal";
import { CategoryContext } from "./CategoryContext";

function Header() {
  const { category } = useContext(CategoryContext);
  const { user, logout } = useContext(UserContext);
  const { cart } = useContext(CartContext);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [itemsCnt, setItemsCnt] = useState(0);

  const handleUserIconClick = () => {
    setLoginModalShow(true);
  };

  const handleClose = () => {
    setLoginModalShow(false);
  };

  const updateCategory = () => {
    setCategoryModalShow(true);
  };
  useEffect(() => {
    setItemsCnt(
      Object.values(cart).reduce(
        (acc, item) => acc + (Number(item.quantity) || 0),
        0
      )
    );
  }, [cart]);

  return (
    <>
      <Navbar expand="md" className="main-navbar">
        <Container>
          {/* Logo */}
          <Navbar.Brand href="/home">
            <img src="/favicon2.ico" alt="WeClear Logo" width="70px" />
          </Navbar.Brand>
          <Navbar.Brand className="logo" href="/home">
            WeClear
          </Navbar.Brand>

          {/* Centered Search Bar */}
          <Nav className="mx-auto d-flex align-items-center">
            <div className="search-bar mx-auto">
              {" "}
              <Search />{" "}
            </div>
          </Nav>

          {/* Navbar Toggle for Mobile View */}
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav">
            {/* Right Aligned Section: User Dropdown and Cart */}
            <Nav className="ms-auto d-flex align-items-center">
              {/* User Dropdown or Login Icon */}
              {user ? (
                <NavDropdown
                  align="end"
                  title={user.firstName}
                  id="nav-dropdown"
                >
                  <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    My bookings
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>
              ) : (
                <Nav.Link onClick={handleUserIconClick}>
                  <FaRegUser size={20} />
                </Nav.Link>
              )}

              {/* Cart Icon with Badge */}
              <Nav.Link as={Link} to="/cart" className="position-relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-cart-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z" />
                  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1zm3.915 10L3.102 4h10.796l-1.313 7zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0m7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
                {Object.keys(cart).length !== 0 && (
                  <span className="cart-badge position-absolute translate-middle badge rounded-pill ">
                    {itemsCnt}
                  </span>
                )}
              </Nav.Link>

              {/* Login Modal */}
              <LoginModal show={loginModalShow} handleClose={handleClose} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {category ? (
        <Navbar expand="lg" className="second-navbar">
          <Container className="justify-content-center m-0 ">
            <Nav className="mx-auto">
              <p>Tranform your Workspace with our Services</p>

{/*
            <Nav.Link onClick={updateCategory} className="">
           {category === "COMMERCIAL" ? "You are currently viewing Commercial services. Choose your services and get a quote." : "You are currently viewing Residential services."}
        </Nav.Link>
*/}
              
            </Nav>
          </Container>
        </Navbar>
      ) : (
        <CategoryModal
          show={true} // Show the modal initially if no category is selected
          handleClose={() => setCategoryModalShow(false)}
        />
      )}

      {/* Always show the modal for updating category */}
      <CategoryModal
        show={categoryModalShow}
        handleClose={() => setCategoryModalShow(false)}
      />
    </>
  );
}

export default Header;
