import React, { useState, useContext } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './styles/LoginModal.css';
import { UserContext } from './UserContext';
import { FaExclamationCircle } from 'react-icons/fa';
import PasswordStrength from './PasswordStrength';

const LoginModal = ({ show, handleClose }) => {
  const [phno, setPhno] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(1);

  const { signup, login } = useContext(UserContext);

  // Validations
  const [errors, setErrors] = useState({});

  const validatePhone = (phone) => {
    const phoneRegex = /^[1-9][0-9]{9}$/; // Starts with 1-9 and has exactly 10 digits
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    // At least 8 characters, one letter, one number, and one special character
    return passwordRegex.test(password);
  };


  const checkUserExistence = async () => {
    try {
      const response = await fetch('https://api.weclear.in/auth/check-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phno }),
        credentials: 'include',
      });

      if (response.ok) {
        const userExists = await response.json();
        return userExists;
      } else {
        throw new Error('Failed to check user existence');
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleValidationErrors = () => {
    const newErrors = {};
    if (!validatePhone(phno)) newErrors.phno = "Invalid phone number.";
    if (!validateEmail(email)) newErrors.email = "Invalid email address.";
    if (!validatePassword(password)) newErrors.password = "Password must be at least 8 characters, with letters, numbers, and special characters.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    if (step === 1) {
      if (!validatePhone(phno)) {
        setErrors({ phno: "Invalid phone number" });
        return;
      }
      
      
      const res = await checkUserExistence();
      if (res) {
        setStep(2);
      } else {
        setStep(3);
      }
    } else if (step === 2) { // Login
      const success = await login(phno, password);
      if (success) {
    
        resetModal();
      }
    } else if (step === 3) { // Signup
     
      // Validate input
      if (!handleValidationErrors()) return;

  
      const success = await signup(firstName, lastName, email, phno, password);
      if (success) {
        
        resetModal();
      }

    }
   
  };

  const resetModal = () => {
    setPhno('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setPassword('');
    setStep(1);
    setErrors({});
    handleClose(); // Trigger parent close
  };

  return (
    <Modal
      show={show}
      onHide={resetModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="full-height-right-modal p-0 m-0"
      style={{
        position: 'fixed',
        right: 0,       // Align to the right
        top: 0,         // Start at the top
        left: 'auto',   // Prevents alignment to the left
        height: '100vh', 
        width: '30vw',  // Adjust width as needed
        maxWidth: '400px',
        margin: 0,
        transform: 'none', // Prevents centering transform
        zIndex: 1050     // Ensures it appears above other elements
      }}
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="fs-4 p-4 ml-4">
          {step === 1 ? 'Login/ Signup' : step === 2 ? 'Login' : 'New User Signup'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} noValidate className="p-3">
          

          {step === 1 && (
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="Phone Number" controlId="phno">
                <Form.Control
                  type="text"
                  value={phno}
                  maxLength={10}
                  onChange={(e) => setPhno(e.target.value)}
                   
                />
                {errors.phno && <p className="error text-danger">
                <FaExclamationCircle className='error-icon' />
                {errors.phno}
                </p>}
              </Form.FloatingLabel>
            </Form.Group>
          )}

          {step === 2 && (
            <Form.Group controlId="pwd" className="mb-3">
              <Form.FloatingLabel label="Password">
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                   
                />
                
              </Form.FloatingLabel>
            </Form.Group>
          )}

          {step === 3 && (
            <>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="First Name" controlId="fname">
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                     
                  />
                </Form.FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Last Name" controlId="lname">
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                     
                  />
                </Form.FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Email" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                     
                  />
                   {errors.email && <p className="error text-danger">
                   <FaExclamationCircle className='error-icon' />
                   {errors.email}
                   </p>}
                  
                </Form.FloatingLabel>
              </Form.Group>
             
              <Form.Group className="mb-3">
  <Form.FloatingLabel label="Password" controlId="pwd">
    <Form.Control
      type="password"
      placeholder="Enter password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
    />
  </Form.FloatingLabel>
  <PasswordStrength password={password} />
</Form.Group>

            </>
          )}
          <Button
            variant="primary"
            type="submit"
            className="w-100 mt-3  custom-btn">
            Continue
          </Button>
        </Form>
        <p className="text-center mt-3" style={{ fontSize: '0.8rem' }}>
            By clicking on Continue, I accept the <span style={{ color: 'orange' }}>Terms & Conditions & Privacy Policy</span>
          </p>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
