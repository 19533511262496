import React, { useState,useEffect, useContext } from 'react';
import { Row, Col, Card, Button, Container } from 'react-bootstrap';
import './styles/QuoteWorks.css';  // Your custom styles for the transition


const QuoteWorks = () => {
  const services = [
    {
      title: "Painting & Wall Finishes",
      tagline:"Color Your World with Perfect Hues",
      description: "Painting is a powerful tool in transforming the mood and perception of any space. Our experts at WeClear help you navigate through the myriad of paint types and finishes to find the perfect shade that resonates with your personality and complements your home's overall theme. From bold accent walls to serene neutral palettes, we ensure that your walls tell your story beautifully.",
      types:["Matte Finish Paint", "Satin Finish Paint", "Eggshell Paint", "Gloss Paint", "Semi-gloss paint"],
      image: "/painting.jpg",
    },
    {
      title: "Ceiling",
      tagline:"Elevate Your Space with Stylish Ceilings",
      description:"False ceilings do more than just hide electrical wiring and improve acoustics—they add depth, character, and elegance to any room. At WeClear, we offer innovative false ceiling designs that range from minimalist to intricate, ensuring they enhance the room's ambiance while providing practical benefits. Let us help you create ceilings that are as functional as they are beautiful.",         
      types:["Tray Ceilings", "Coffered Ceilings", "Beam Ceilings", "Stretch Ceilings"],
      image: "/path/to/ceiling.jpg",
    },
    {
      title: "Flooring",
      tagline: "The Foundation of Your Home’s Aesthetic",
      description: "Your choice of flooring sets the stage for your entire home decor. Whether you prefer the timeless elegance of hardwood, the versatility of laminate, the durability of tile, the comfort of carpet, or the affordability of vinyl, flooring is the canvas upon which all other design elements are built. At WeClear, we guide you through choosing the perfect flooring that balances style and practicality, ensuring it aligns with your lifestyle and design preferences.",
      types:["Tile", "Hardwood", "Laminate", "Carpet", "Vinyl"],
      image: "/path/to/flooring.jpg",
    },
    {
      title: "Lighting",
      tagline: "Illuminate Your Space with Perfect Lighting",
      description: "Lighting is crucial in setting the mood and enhancing the functionality of your space. From ambient lighting that provides overall illumination to task lighting for specific activities and accent lighting that highlights architectural features, WeClear offers a comprehensive guide to mastering the art of lighting. Discover how different lighting solutions can transform your rooms into warm, welcoming, and dynamic spaces.",
      types:["Ambient Lighting", "Task Lighting", "Accent Lighting", "Natural Lighting"],
      image: "/path/to/lighting.jpg",
    },
    {
      title: "Furniture & Storage",
      tagline: "Bringing Comfort and Style into Your Home",
      description: "Furniture is the soul of your home, reflecting your taste and providing comfort. Whether you lean towards modern minimalism, classic elegance, rustic charm, or eclectic flair, WeClear helps you choose pieces that are both aesthetically pleasing and functional. Our curated collections and expert advice ensure that each piece fits perfectly in your space, creating a harmonious and inviting atmosphere.",
      types:["Modern", "Classic", "Rustic", "Minimalist", "Eclectic"],
      image: "/path/to/lighting.jpg",
    },
  ];

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Trigger animation on component load
    setLoaded(true);
  }, []);

  const handleQuoteRequest = async () => {
    const quoteData = {
      serviceName: "Painting", // Example
      customerName: "John Doe",
      customerEmail: "john@example.com",
    };
  
    const response = await fetch("/request-quote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(quoteData),
    });
  
    const data = await response.json();
    if (data.message === "Quote request submitted successfully!") {
      alert("Your quote request has been submitted!");
    }
  };

  return (
    
    <Container >
      {services.map((service, index) => (
          <Card key={index} className={`d-flex flex-column justify-content-between service-card ${index % 2 === 0 ? 'image-left' : 'image-right'}`}>
          <Row className="no-gutters">
            {/* Image Column */}
            <Col md={6} className="card-image">
              <Card.Img src={service.image} alt={service.title} />
            </Col>
            {/* Content Column with animation */}
            <Col
              md={6}
              className={`card-content ${loaded ? 'content-appear' : 'content-hidden'}`}
            >
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Subtitle>{service.tagline}</Card.Subtitle>
                <Card.Text>{service.description}</Card.Text>
                {service.types.map((type, index)=> (
                  <Button className='btn btn-outline rounded-pill'>{type}</Button>
                ))}
               <div className='d-flex justify-content-end mt-3'>
                <Button variant="primary" className='custom-btn'>Get a Quote</Button>
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>

      ))}
    </Container>
    
  );
};

export default QuoteWorks;
