import React, { useState, useEffect } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import './styles/Header.css'
import { FaSearch, FaTimesCircle } from 'react-icons/fa';



function Search() {
  const [query, setQuery] = useState('');
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  // Fetch all services from the API
  useEffect(() => {
    
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://api.weclear.in/services/getservices'); // Adjust the endpoint as needed
        setServices(response.data);
        setFilteredServices(response.data); // Initially show all services
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);


  // Filter services based on the search query
  useEffect(() => {
    if (query) {
      setFilteredServices(
        services.filter(service =>
          service.name.toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setFilteredServices([]);
    }
  }, [query, services]);

  // Handle search input change
  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  // Handle list item click
  const handleSelect = (service) => {
    console.log('Selected service:', service);
    // Perform an action with the selected service, e.g., navigate to service details
    setQuery(service.name); // Optionally set the query to the selected service name
  };



  return (
    <header>
      <div className="search-container">
        
        <Form.Control     
          type="text"
          placeholder="Search services..."
          className = "search-input"
          value={query}
          onChange={handleSearchChange}
        />

        {query.length>0 && (
            <FaTimesCircle className='close-icon' size={14} onClick={()=> setQuery('')}></FaTimesCircle>
        )}      
        <FaSearch className='search-icon' size={14}></FaSearch>

       
        
        {filteredServices.length > 0 && (
          <ListGroup className="search-results">
            {filteredServices.map(service => (
              <ListGroup.Item
                key={service.id}
                onClick={() => handleSelect(service)}
                action
              >
                {service.name}
              </ListGroup.Item>
            ))}
            {filteredServices.length === 0 && (
              <ListGroup.Item disabled>No services found</ListGroup.Item>
            )}
          </ListGroup>
        )}
      </div>
    </header>
  );
}

export default Search;